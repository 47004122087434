<template>
  <div class="dkb">
    <b-row class="match-height">
      <b-col>
        <apex-data-bar-chart
          ref="chart1"
          Bybit="Bybit"
          :chartType="1"
          @changeCurrency="changeCurrency"
          @changeTradingOn="changeTradingOn"
          @changeBourse="changeBourse"
          @changeTime="changeTime"
          title="Bybit 账户数多空比值"
        />
      </b-col>
    </b-row>
    <b-row class="match-height">
      <b-col>
        <proportion
          ref="chart2"
          Bybit="Bybit"
          :chartType="2"
          @changeCurrency="changeCurrency"
          @changeTradingOn="changeTradingOn"
          @changeBourse="changeBourse"
          @changeTime="changeTime"
          title="Bybit 账户数多空比例"
        />
      </b-col>
    </b-row>
    <b-row class="match-height">
      <b-col>
        <difference
          ref="chart3"
          Bybit="Bybit"
          :chartType="3"
          @changeCurrency="changeCurrency"
          @changeTradingOn="changeTradingOn"
          @changeBourse="changeBourse"
          @changeTime="changeTime"
          title="Bybit 账户数多空差值"
        />
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { BRow, BCol, BLink } from "bootstrap-vue";
import { getBybitAccountNumberData } from "@/api/LongShortRatio.js";

// import ApexLineChart from "@/views/charts-and-maps/charts/apex-chart/ApexLineChart.vue";
import ApexDataBarChart from "@/views/charts-and-maps/charts/apex-chart/ApexDataBarChart.vue";
import difference from "@/views/charts-and-maps/charts/echart/option-echart/Difference.vue";
import proportion from "@/views/charts-and-maps/charts/echart/option-echart/Proportion.vue";

export default {
  components: {
    BRow,
    BCol,
    BLink,
    ApexDataBarChart,
    difference,
    proportion,
  },
  data() {
    return {
      requestParams: {
        type: "3",
        cName: "BTC",
        period: "5m",
        tradingOn: "USD",
      },
    };
  },
  mounted() {
    this.getMapList(0); //2执行请求
  },
  methods: {
    //处理时间的方法
    gettime(datetime) {
      let utcDateTime = datetime.replace(" ", "T") + ".000Z";
      let time = new Date(utcDateTime);
      let year = time.getFullYear();
      let month = time.getMonth() + 1;
      let date = time.getDate();
      let hour = time.getHours();
      let minute = time.getMinutes();
      let second = time.getSeconds();
      month = month > 9 ? month : "0" + month;
      date = date > 9 ? date : "0" + date;
      hour = hour > 9 ? hour : "0" + hour;
      minute = minute > 9 ? minute : "0" + minute;
      second = second > 9 ? second : "0" + second;
      let localDateTime =
        year +
        "-" +
        month +
        "-" +
        date +
        " " +
        hour +
        ":" +
        minute +
        ":" +
        second;
      return localDateTime;
    },
    // 1请求全部数据
    getMapList(type) {
      let params = {
        type: "3",
        period: this.requestParams.period,
        cname: this.requestParams.cName,
        stype: this.requestParams.tradingOn,
        ename: "1",
        // utime: `${new Date(new Date().toUTCString()).getTime() / 1000}`,
      };
      getBybitAccountNumberData(params)
        .then((res) => {
          let resData = res.data.data;
          if (resData && resData.length > 0) {
            resData.forEach((v, k) => {
              v.datetime = this.gettime(v.datetime);
            });
          }
          if (type === 0) {
            this.dealEchartData(1, resData);
            this.dealEchartData(2, resData);
            this.dealEchartData(3, resData);
          } else {
            this.dealEchartData(type, resData);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    dealEchartData(type, resData) {
      let period = this.requestParams.period;
      if (type === 1) {
        let chart1Xdata = this.getEChartXdata(resData);
        // console.log(3,resData)
        this.$refs.chart1.chart.setOption({
          legend: {
            data: ["账户数多空比值"],
            textStyle: {
              color: "#FFF",
            },
          },
          yAxis: {
            type: "value",
            scale: true,
            axisLabel: {
              textStyle: {
                color: "#FFF",
              },
            },
            splitLine: {
                show: true,
                lineStyle:{
                    type:'dashed',
                    color: "rgba(255, 255, 255, 0.2)",
                }
            }
          },
          xAxis: {
            data: chart1Xdata.reverse(),
            //新增的绘制点
            axisLabel: {
              textStyle: {
                color: "#FFF",
              },
              formatter: (val) => {
                if (period == "1d") {
                  return val.split(" ")[0];
                }
                return (
                  val.split(" ")[0].slice(5, 10) +
                  " " +
                  val.split(" ")[1].slice(0, 5)
                );
              },
            },
          },
          tooltip: {
            trigger: "axis",
            axisPointer: {
              type: "cross",
              label: {
                backgroundColor: "#283b56",
              },
            },
          },
          dataZoom: [
            {
              type: "inside",
              start: 0,
              end: 100,
            },
            {
              start: 0,
              end: 100,
            },
          ],
          series: [
            {
              name: "账户数多空比值",
              data: this.getEchartYdata(
                false,
                "globalaccount_longshortaccount",
                resData
              ).reverse(),
            },
          ],
        });
      } else if (type === 2) {
        let chart2Xdata = this.getEChartXdata(resData);
        this.$refs.chart2.chart.setOption({
          legend: {
            data: ["账户数多头比例", "账户数空头比例"],
            textStyle: {
              color: "#FFF",
            },
          },
          yAxis: {
            type: "value",
            // scale: true,
            axisLabel: {
              textStyle: {
                color: "#FFF",
              },
            },
            splitLine: {
                show: true,
                lineStyle:{
                    type:'dashed',
                    color: "rgba(255, 255, 255, 0.2)",
                }
            }
          },
          xAxis: {
            data: chart2Xdata.reverse(),
            //新增的绘制点
            axisLabel: {
              textStyle: {
                color: "#FFF",
              },
              formatter: (val) => {
                if (period == "1d") {
                  return val.split(" ")[0];
                }
                return (
                  val.split(" ")[0].slice(5, 10) +
                  " " +
                  val.split(" ")[1].slice(0, 5)
                );
              },
            },
          },
          tooltip: {
            trigger: "axis",
            axisPointer: {
              type: "cross",
              label: {
                backgroundColor: "#283b56",
              },
            },
          },
          dataZoom: [
            {
              type: "inside",
              start: 80,
              end: 100,
            },
            {
              start: 80,
              end: 100,
            },
          ],
          series: [
            {
              name: "账户数多头比例",
              data: this.getEchartYdata(
                true,
                "globalaccount_longaccount",
                resData
              ).reverse(),
              itemStyle: {
                color: {
                  x: 0,
                  y: 0,
                  x2: 0,
                  y2: 1,
                  colorStops: [
                    {
                      offset: 0.1,
                      color: 'rgba(70, 193, 149, 1)', // 0% 处的颜色
                    },
                    {
                      offset: 0.9,
                      color: 'rgba(124, 226, 199, 0)', // 100% 处的颜色
                    },
                  ],
                  globalCoord: false, // 缺省为 false
                },
              },
            },
            {
              name: "账户数空头比例",
              data: this.getEchartYdata(
                true,
                "globalaccount_shortaccount",
                resData
              ).reverse(),
              itemStyle: {
                color: {
                  x: 0,
                  y: 0,
                  x2: 0,
                  y2: 1,
                  colorStops: [
                    {
                      offset: 0.1,
                      color: 'rgba(255, 82, 96, 1)', // 0% 处的颜色
                    },
                    {
                      offset: 0.9,
                      color: 'rgba(255, 82, 96, 0)', // 100% 处的颜色
                    },
                  ],
                  globalCoord: false, // 缺省为 false
                },
              },
            },
          ],
        });
      } else if (type === 3) {
        //第三个图表
        let chart3Xdata = this.getEChartXdata(resData);
        this.$refs.chart3.chart.setOption({
          legend: {
            data: ["账户数多空差值"],
            textStyle: {
              color: "#FFF",
            },
            itemStyle: {
              color: "#2EBD85",
            },
          },
          yAxis: {
            type: "value",
            scale: true,
            axisLabel: {
              textStyle: {
                color: "#FFF",
              },
            },
            splitLine: {
                show: true,
                lineStyle:{
                    type:'dashed',
                    color: "rgba(255, 255, 255, 0.2)",
                }
            }
          },
          xAxis: {
            data: chart3Xdata.reverse(),
            //新增的绘制点
            axisLabel: {
              textStyle: {
                color: "#FFF",
              },
              formatter: (val) => {
                if (period == "1d") {
                  return val.split(" ")[0];
                }
                return (
                  val.split(" ")[0].slice(5, 10) +
                  " " +
                  val.split(" ")[1].slice(0, 5)
                );
              },
            },
          },
          dataZoom: [
            {
              type: "inside",
              start: 50,
              end: 100,
            },
            {
              start: 50,
              end: 100,
            },
          ],
          series: [
            {
              name: "账户数多空差值",
              data: this.getEchartYdata(
                false,
                "globalaccount_difference",
                resData
              ).reverse(),
              itemStyle: {
                color: function (params) {
                  let num = params.value;
                  // console.log("num", num);
                  if (num > 0) {
                    return "#46C195";
                  } else {
                    return "rgba(255, 82, 96, 1)";
                  }
                },
              },
            },
          ],
        });
      }
    },

    //货币筛选
    changeCurrency(obj) {
      this.requestParams = { ...obj.allData, type: obj.type };
      this.sendRequest(obj.type);
    },
    //交易对筛选
    changeTradingOn(obj) {
      this.requestParams = { ...obj.allData, type: obj.type };
      this.getMapList(obj.type);
    },
    //交易所筛选
    changeBourse(obj) {
      this.requestParams = { ...obj.allData, type: obj.type };
      this.getMapList(obj.type);
    },
    //时间筛选
    changeTime(obj) {
      this.requestParams = { ...obj.allData, type: obj.type };
      console.log(this.requestParams);
      this.getMapList(obj.type);
    },
    //获取时间间隔
    getEChartXdata(resData) {
      // console.log('3',timeArr)
      let timeArr = resData.map((v) => v.datetime);
      return timeArr;
    },
    getEchartYdata(isPercent, key, resData) {
      // console.log('resData',resData)
      let tempArr = [];
      if (isPercent) {
        tempArr = resData.map((v) => (v[key] * 100).toFixed(2));
      } else {
        tempArr = resData.map((v) => v[key]);
      }
      // console.log('tempArr',tempArr)
      return tempArr;
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-flatpicker.scss";
@import "@core/scss/vue/libs/chart-apex.scss";
.dkb {
  .el-input__inner {
    color: #fff;
    border-color: #fff !important;
  }
  .el-input__inner:focus {
    border-color: #EFC394 !important;
  }
}
.el-select-dropdown {
  .el-scrollbar {
    .el-select-dropdown__item.selected {
      color: #EFC394 !important;
    }
  }
}
</style>
